<template>
  <div>
    <el-dialog
      title="退款列表"
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="info">
        <div class="info-left">
          <div class="info-left-img">
            <img :src="info.member_avatar" v-if="info.member_avatar" />
            <img v-else src="../../../../assets/images/no_user.png" />
          </div>
          <div>
            <div style="margin-bottom: 15px;">会员名称：{{info.member_name || '--'}}</div>
            <div>手机号：{{info.member_phone || '--'}}</div>
          </div>
        </div>
        <div class="info-right">
          <div style="margin-bottom: 15px;">
            订单号：<span>{{info.order_number || '--'}}</span>
          </div>
          <div>
            下单时间：<span>{{info.create_time || '--'}}</span>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          v-if="rowList.length"
          v-loading="loading"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods_pic' && item.field_alias !== 'goods_name'">
                  {{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias] : '--'}}
                </template>
                <template v-if="item.field_alias === 'goods_name'">
                  <div style="display: flex;  align-items: center; justify-content: center;">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                        <template v-for="item in scope.row.goods_tag_pic">
                          <img :src="item" :key="item" style="margin-left: 5px;" />
                        </template>
                      </div>
                </template>
                <template v-if="item.field_alias === 'goods_pic'">
                  <img :src="scope.row[item.field_alias]" v-if="scope.row[item.field_alias]" class="img" />
                  <img v-else src="../../../../assets/images/no_img.png" class="img" />
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd(scope.row)" v-if="!scope.row.isAdd" :disabled="scope.row.canDoPcs === 0">添加</el-button>
              <el-button type="text" @click="handleAdd(scope.row)" v-else disabled>已添加</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="title">已添加的退货货品</div>
      <el-table
          :data="returnTableData"
          style="width: 100%"
          border
        >
          <template v-for="item in returnRowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods_pic' && item.field_alias !== 'goods_pcs' && item.field_alias !== 'pay_ammout' && item.field_alias !== 'goods_name'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                </template>
                <template v-if="item.field_alias === 'goods_pcs'">
                  <span v-if="scope.row.goods_number_type === 10">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                  <div class="input" v-else>
                    <!-- <el-input v-model="scope.row.goods_pcs" type="number" :max="scope.row.goods_pcs" :disabled="refund_order_id && (scope.row.canDoPcs !== scope.row.origin_goods_pcs) ? true : false"></el-input> -->
                    <el-input v-model="scope.row.goods_pcs" type="number" :max="scope.row.goods_pcs"></el-input>
                  </div>
                </template>
                <template v-if="item.field_alias === 'pay_ammout'">
                  <div class="input">
                    <!-- <el-input v-model="scope.row.pay_ammout" :disabled="refund_order_id && (scope.row.canDoPcs !== scope.row.origin_goods_pcs) ? true : false"> -->
                    <el-input v-model="scope.row.pay_ammout">
                    </el-input>
                  </div>
                </template>
                <template v-if="item.field_alias === 'goods_pic'">
                  <img :src="scope.row[item.field_alias]" v-if="scope.row[item.field_alias]" class="img" />
                  <img v-else src="../../../../assets/images/no_img.png" class="img" />
                </template>
                <template v-if="item.field_alias === 'goods_name'">
                  <div style="display: flex;  align-items: center; justify-content: center;">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                        <template v-for="item in scope.row.goods_tag_pic">
                          <img :src="item" :key="item" style="margin-left: 5px;" />
                        </template>
                      </div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <!-- <el-button type="text" style="color: #E7541E;" @click="handleDele(scope.row)" :disabled="refund_order_id && (scope.row.canDoPcs !== scope.row.origin_goods_pcs) ? true : false">移除</el-button> -->
              <el-button type="text" style="color: #E7541E;" @click="handleDele(scope.row)">移除</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="title">付款渠道</div>
      <el-form :model="form" label-width="100px">
        <el-form-item label="经办人" required>
          <el-select v-model="form.store_user_id">
            <template v-for="item in employeeList">
              <el-option v-if="item.store_user_id" :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式" required>
          <el-select v-model="form.pay_channel">
            <template v-for="item in payList">
              <el-option :label="item.name" :value="item.pay_type" :key="item.pay_type"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input type="textarea" :rows="5" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="退款时间" v-if="refund_order_id">
           <el-date-picker
              v-model="create_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              align="right">
            </el-date-picker>
        </el-form-item>
      </el-form>
       <el-divider></el-divider>
      <div class="title">结算金额</div>
      <div class="list">
        <div class="list-item">
          <div>退款金额</div>
          <div>￥{{total}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit" :loading="loading2">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import numeral from 'numeral';
import { getOrderInfoReq, getOrderGoodsTitleReq, resetRefundOrderDetailReq, resetRefundOrderReq } from '@/api/order/offlineOrder/list';
import { getEmployeeReq, createReturnReq } from '@/api/order/offlineOrder/add';
import { getListReq as getPayListReq } from '@/api/system/settleConfig.js';

export default {
  data() {
    return {
      info: {}, // 退货单信息
      create_time: '',
      payList: [],
      loading2: false,
      total: '0.00',
      loading: false,
      orderInfo: {}, // 订单信息
      employeeList: [], // 员工列表
      form: {
        create_time: '',
      },
      rowList: [],
      returnRowList: [
        { field_text: '序号', field_alias: 'order_goods_index' },
        { field_text: '货品图片', field_alias: 'goods_pic' },
        { field_text: '货号', field_alias: 'goods_number', t_width: 150 },
        { field_text: '品名', field_alias: 'goods_name', t_width: 300 },
        // { field_text: '储值金抵扣', field_alias: 'order_storage_money' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '货品类型', field_alias: 'goods_number_type_name' },
        { field_text: '退货数量', field_alias: 'goods_pcs' },
        { field_text: '退还积分', field_alias: 'get_integal' },
        { field_text: '退还储值金(单件)', field_alias: 'order_storage_money', t_width: 150 },
        { field_text: '标签价', field_alias: 'sale_price' },
        { field_text: '实收金额', field_alias: 'actual_sale_price' },
        { field_text: '退款金额(单件)', field_alias: 'pay_ammout', t_width: 200 },
      ],
      tableData: [],
      returnTableData: [],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
    order_id: {
      type: Number,
    },
    refund_order_id: {
      type: Number,
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
        } else {
          this.form = {};
          this.returnTableData = [];
        }
      },
    },
    returnTableData: {
      handler() {
        this.handleTotal();
      },
      deep: true,
    },
  },
  created() {
    this.getEmployee();
    this.getOrderGoodsTitle();
    this.getPayList();
  },
  methods: {
    getPayList() {
      getPayListReq({
        status: 10,
      })
        .then((res) => {
          if (res.code === 1) {
            this.payList = res.data;
          }
        });
    },
    // 提交退货单
    onSubmit() {
      // 限制
      if (!this.returnTableData.length) {
        this.$message.error('请选择退货货品');
        return;
      }
      for (let i = 0; i < this.returnTableData.length; i ++) {
        const rItem = this.returnTableData[i];
        for (let j = 0; j < this.tableData.length; j ++) {
          const tItem = this.tableData[j];
          if (rItem.goods_id === tItem.goods_id) {
            if (rItem.goods_pcs > tItem.canDoPcs) {
              if (!this.refund_order_id) {
                this.$message.error(`第${i + 1}条退货货品退款数量超过可退数量`);
                return;
              }
            }
          }
        }
      }
      // 组装后端需要的数据
      const list = [];
      const gift = [];
      for (let i = 0; i < this.returnTableData.length; i += 1) {
        const item = this.returnTableData[i];
        if (Number(item.pay_ammout) > Number(item.oneprice)) {
          this.$message.warning('退款金额不得大于实收金额');
          return;
        }
        if (item.goods_tag_id.indexOf('8') > -1) {
          const obj = {
            goods_number: item.goods_number,
            num: item.goods_pcs,
            pay_ammout: item.pay_ammout,
            warehouse_id: item.warehouse_id,
          };
          gift.push(obj);
        } else {
          const obj = {
            goods_number: item.goods_number,
            num: item.goods_pcs,
            pay_ammout: item.pay_ammout,
            warehouse_id: item.warehouse_id,
          };
          if (this.refund_order_id) {
            obj.stockPcs = item.stockPcs;
            obj.origin_goods_pcs = item.origin_goods_pcs;
          }
          list.push(obj);
        }
      }
      // 创建退货单请求
      this.loading2 = true;
      if (!this.refund_order_id) {
        createReturnReq({
          ...this.form,
          order_id: this.order_id,
          client_user_id: this.info.member_id,
          list,
          gift,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading2 = false;
              this.$message.success('退款成功');
              this.$emit('onHideReturn', 'getList');
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      } else {
        resetRefundOrderReq({
          ...this.form,
          create_time: this.create_time,
          order_id: this.order_id,
          client_user_id: this.info.member_id,
          original_refund_order_id: this.refund_order_id,
          list,
          gift,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading2 = false;
              this.$message.success('退款成功');
              this.$emit('onHideReturn', 'getList');
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      }
    },
    // 计算价钱
    handleTotal() {
      let total = 0;
      this.returnTableData.forEach((item) => {
        total += Number(item.pay_ammout) * item.goods_pcs;
      });
      this.total = numeral(total).format('0.00');
    },
    // 移除
    handleDele(row) {
      // const currRow = { ...row, isAdd: false };
      this.tableData.forEach((item, index) => {
        if (item.goods_id === row.goods_id) {
          this.tableData.splice(index, 1, { ...item, isAdd: false });
        }
      });
      this.returnTableData = this.returnTableData.filter((item) => item !== row);
    },
    // 添加退货商品
    handleAdd(row) {
      const currRow = { ...row, isAdd: true, goods_pcs: row.canDoPcs, pay_ammout: Number(row.oneprice) };
      this.tableData.forEach((item, index) => {
        if (item.goods_id === row.goods_id) {
          this.tableData.splice(index, 1, currRow);
        }
      });
      this.returnTableData.push({ ...currRow });
    },
    // 经办人列表
    getEmployee() {
      getEmployeeReq()
        .then((res) => {
          if (res.code === 1) {
            this.employeeList = res.data;
          }
        });
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('onHideReturn');
    },
    // 获取订单货品表头
    getOrderGoodsTitle() {
      getOrderGoodsTitleReq()
        .then((res) => {
          if (res.code === 1) {
            res.data.forEach((item) => {
              if (item.field_alias === 'goods_number') {
                item.t_width = 150;
              } else if(item.field_alias === 'goods_name') {
                item.t_width = 300;
              }
            });
            this.rowList = res.data;
            if (!this.refund_order_id) {
              // 非反确认的
              this.getOrderInfo(this.order_id);
            } else {
              // 反确认的
              this.resetRefundOrderDetail();
            }
          }
        });
    },
    // 获取反确认订单详情
    resetRefundOrderDetail() {
      resetRefundOrderDetailReq({
        refund_order_id: this.refund_order_id,
      })
        .then((res) => {
          if (res.code === 1) {
            // 接口结构改变
            this.info = res.data;
            this.tableData = res.data.goods.filter((item) => !item.isRaw);
            this.returnTableData = res.data.refund_goods;
            this.form = {
              pay_channel: res.data.pay_channel,
              store_user_id: res.data.store_user_id,
              remark: res.data.remark,
            };
            this.create_time = res.data.create_time;

            // this.orderInfo = res.data.order.info;
            // this.tableData = res.data.order.goodsList.filter((item) => !item.isRaw);
            // this.returnTableData = res.data.refund.goodsList;
            // this.create_time = res.data.order.info.create_time;
            this.tableData.forEach((item) => {
              this.returnTableData.forEach((data) => {
                if (item.goods_id === data.goods_id) {
                  item.isAdd = true;
                }
              });
            });
            this.handleTotal();
            // this.form = res.data.refund.info;
          }
        });
    },
    // 获取订单详情
    getOrderInfo(order_id) {
      this.loading = true;
      getOrderInfoReq(order_id)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.info = res.data;
            this.create_time = res.data.create_time;
            this.tableData = res.data.goods.filter((item) => !item.isRaw);
            // this.orderInfo = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.input {
  /deep/ .el-input__inner  {
    text-align: center;
  }
}
.img {
  width: 54px;
  height: 54px;
  margin-top: 5px;
  object-fit: cover;
}
/deep/ .el-textarea__inner {
  width: 40%;
}
.info {
  display: flex;
  align-items: center;
  &-left {
    display: flex;
    align-items: center;
    &-img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #ccc;
      }
    }
  }
  &-right {
    margin-left: 100px;
    div {
      // color: #ccc;
      span {
        color: #000;
        // margin-left: 10px;
      }
    }
  }
}
.title {
  line-height: 40px;
  font-weight: bold;
}
.list {
  &-item {
    display: flex;
    align-items: center;
    div:nth-child(2) {
      margin-left: 20px;
      color: #E7541E;
      font-size: 16px;
    }
  }
}
</style>
