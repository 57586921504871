<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="新增退货单"> </el-page-header>
    </div>
    <div class="form">
      <el-form v-model="form" :inline="true" label-width="80px">
        <el-form-item label="单据日期">
          <el-date-picker
            :picker-options="pickerBeginDateBefore"
            v-model="form.create_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称">
          <span v-if="!form.member_name">--</span>
          <span v-else>{{ form.member_name }}</span>
        </el-form-item>
        <el-form-item label="门店">
          <span>{{ form.hierarchy_name }}</span>
        </el-form-item>
        <el-form-item label="原订单号">
          <span v-if="!form.order_number">--</span>
          <span v-else>{{ form.order_number }}</span>
        </el-form-item>
        <el-form-item label="导购员">
          <span v-if="!form.main_store_name">--</span>
          <span v-else>{{ form.main_store_name }}</span>
        </el-form-item>
        <div>
          <el-form-item label="货号">
            <el-input
              id="input"
              clearable=""
              v-model="goods_number"
              placeholder="请扫描条码或手动填写"
              @keydown.enter.native="handleSearch"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">筛选货品</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        ref="table"
        border
        :data="list"
        stripe
        v-loading="loading"
        height="420"
        :row-class-name="handleIndex"
        :summary-method="getSummaries"
        :show-summary="false"
      >
        <template v-for="(item, index) in columnList">
          <el-table-column
            align="center"
            :key="index"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template v-if="item.field_alias === 'index'">
                {{ scope.row.index + 1 }}
              </template>
              <template v-else-if="item.field_alias === 'goods_pic'">
                <el-image
                  style="width: 54px; height: 54px; margin-top: 5px"
                  :src="scope.row.goods_pic"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <img
                      style="width: 54px; height: 54px; margin-top: 5px"
                      src="../../../../assets/images/nopic.png"
                    />
                  </div>
                </el-image>
              </template>
              <template v-else-if="item.field_alias === 'goods_name'">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                      <template v-for="item in scope.row.goods_tag_pic">
                        <img :src="item" :key="item" style="margin-left: 5px" />
                      </template>
                    </div>
              </template>
              <template v-else-if="item.field_alias === 'return_num'">
                  <span v-if="scope.row.goods_number_type === 10">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                  <div class="input" v-else>
                    <el-input v-model="scope.row.return_num" style="text-align: center;" type="number" :max="scope.row.canDoPcs"></el-input>
                  </div>
              </template>
              <template v-else-if="item.field_alias === 'pay_ammout'">
                <div class="input" v-if="!scope.row.isGift">
                  <el-input v-model="scope.row.pay_ammout" type="number">
                  </el-input>
                </div>
                <span v-else>{{scope.row.pay_ammout}}</span>
              </template>
              <template v-else>
                {{
                  scope.row[item.field_alias] ||
                  scope.row[item.field_alias] === 0
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          field_alias="operation"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleDele(scope.row.index)"
              style="color: #f56c6c"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="form">
      <el-form v-model="form" :inline="true" label-width="80px">
        <el-form-item label="经办人" required>
          <el-select v-model="form.store_user_id">
            <template v-for="item in employeeList">
              <el-option v-if="item.store_user_id" :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="退款方式" required>
          <el-select v-model="form.pay_channel">
            <template v-for="item in payList">
              <el-option :label="item.name" :value="item.pay_type" :key="item.pay_type"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="退款金额" required="">
          <span>{{total}}</span>
        </el-form-item>
        <div>
        <el-form-item label="订单备注">
          <el-input type="textarea" :rows="5" v-model="form.remark" style="width: 535px;"></el-input>
        </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="btn2">
      <el-button type="primary" @click="onSubmit" :loading="saveLoading">确定</el-button>
      <el-button @click="goBack">取消</el-button>
    </div>
    <el-dialog title="选择退货商品" :visible.sync="dialogVisible" width="70%">
      <div style="height: 500px; overflow-y: auto; overflow-x: hidden">
        <div v-for="item in orderInfo.list" :key="item.order_id">
          <el-form :model="orderInfo" :inline="true" label-width="90px">
            <el-form-item label="订单号:">
              <span>{{ item.order_number }}</span>
            </el-form-item>
            <el-form-item label="下单时间:">
              <span>{{ item.create_time }}</span>
            </el-form-item>
            <el-form-item label="会员:">
              <span>{{ item.member_name }}</span>
            </el-form-item>
            <el-form-item label="主销售:">
              <span>{{ item.main_store_name }}</span>
            </el-form-item>
          </el-form>
          <el-table
            :row-class-name="handleIndex"
            :data="item.goods"
            style="width: 100%"
            border
            v-if="columnList2.length"
            v-loading="loading"
          >
            <template v-for="item in columnList2">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width"
              >
                <template slot-scope="scope">
                  <template
                    v-if="
                      item.field_alias !== 'goods_pic' &&
                      item.field_alias !== 'goods_name'
                    "
                  >
                    {{
                      scope.row[item.field_alias] ||
                      scope.row[item.field_alias] === 0
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </template>
                  <template v-if="item.field_alias === 'goods_name'">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                      <template v-for="item in scope.row.goods_tag_pic">
                        <img :src="item" :key="item" style="margin-left: 5px" />
                      </template>
                    </div>
                  </template>
                  <template v-if="item.field_alias === 'goods_pic'">
                    <img
                      :src="scope.row[item.field_alias]"
                      v-if="scope.row[item.field_alias]"
                      class="img"
                    />
                    <img
                      v-else
                      src="../../../../assets/images/no_img.png"
                      class="img"
                    />
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align="center"
              fixed="right"
              field_alias="operation"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleAdd(item, scope.row.index)"
                  >添加</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import numeral from 'numeral';
import storage from "good-storage";
import { getOrderListByGoodsNumberReq, getEmployeeReq, createReturnReq  } from "@/api/order/offlineOrder/add";
import { getOrderGoodsTitleReq } from "@/api/order/offlineOrder/list";
import { getListReq as getPayListReq } from '@/api/system/settleConfig.js';

export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      saveLoading: false,
      total: '0.00',
      employeeList: [],
      payList: [],
      orderInfo: {},
      dialogVisible: false,
      goods_number: "",
      userInfo,
      loading: false,
      list: [],
      form: {},
      columnList2: [], // 退货表头
      columnList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "货品图片", field_alias: "goods_pic" },
        { field_text: "货号", field_alias: "goods_number" },
        { field_text: "品名", field_alias: "goods_name", t_width: 200 },
        { field_text: "品类", field_alias: "goods_type_name" },
        { field_text: "货品类型", field_alias: "goods_number_type_name" },
        { field_text: "退货数量", field_alias: "return_num", isSum: true },
        {
          field_text: "退还积分(单件)",
          field_alias: "get_integal",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "退还储值金(单件)",
          field_alias: "order_storage_money",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "标签价(单件)",
          field_alias: "sale_price",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "实收金额(单件)",
          field_alias: "actual_sale_price",
          isSum: true,
          t_width: 150,
        },
        // { field_text: "折旧率", field_alias: "id12", isSum: true },
        {
          field_text: "实退金额(单件)",
          field_alias: "pay_ammout",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "成本价(单件)",
          field_alias: "cost_price",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "主石重(单件)",
          field_alias: "msct",
          isSum: true,
          t_width: 150,
        },
        {
          field_text: "幅石1重(单件)",
          field_alias: "osct",
          isSum: true,
          t_width: 150,
        },
        // { field_text: "备注", field_alias: "remark" },
      ],
    };
  },
  watch: {
    list: {
      handler() {
        this.getTotal();
      },
      deep: true,
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs['table'].doLayout();
    })
  },
  mounted() {
    document.getElementById("input").focus();
  },
  created() {
    this.getOrderGoodsTitle();
    const create_time = moment().format("YYYY-MM-DD");
    this.form = {
      ...this.form,
      hierarchy_name: this.userInfo.hierarchy_name,
      create_time,
    };
    this.getPayList();
    this.getEmployee();
  },
  methods: {
    // 提交退货单
    onSubmit() {
      // 限制
      if (!this.list.length) {
        this.$message.error('请选择退货货品');
        return;
      }
      // 组装后端需要的数据
      const list = [];
      const gift = [];
      for (let i = 0; i < this.list.length; i += 1) {
        const item = this.list[i];
        if (Number(item.return_num) > Number(item.canDoPcs)) {
          this.$message.warning(`第${i + 1}件货品退货数量最多为${item.canDoPcs}`);
          return;
        }
        if (Number(item.pay_ammout) > Number(item.oneprice)) {
          this.$message.warning(`第${i+1}件货品退款金额不得大于实收金额`);
          return;
        }
        if (item.goods_tag_id.indexOf('8') > -1) {
          const obj = {
            goods_number: item.goods_number,
            num: item.return_num,
            pay_ammout: item.pay_ammout,
            warehouse_id: item.warehouse_id,
          };
          gift.push(obj);
        } else {
          const obj = {
            goods_number: item.goods_number,
            num: item.return_num,
            pay_ammout: item.pay_ammout,
            warehouse_id: item.warehouse_id,
          };
          list.push(obj);
        }
      }
      // 创建退货单请求
      this.saveLoading = true;
      createReturnReq({
        ...this.form,
        order_id: this.form.order_id,
        client_user_id: this.form.member_id,
        list,
        gift,
      })
        .then((res) => {
          if (res.code === 1) {
            this.saveLoading = false;
            this.$message.success('退款成功');
            this.$emit('onBack', 'getList');
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    getTotal() {
      let total = 0;
      this.list.forEach((item) => {
        total = Number(total) + Number(item.return_num * item.pay_ammout)
      })
      this.total = numeral(total).format('0.00');
    },
     getSummaries(param) {
        const { columns, data } = param;
        console.log(columns);
        console.log(data);
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          if (column.property !== 'goods_number' && column.property !== 'return_num') {
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              console.log(values);
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            }
          }
        });

        return sums;
      },
    // 付款方式
    getPayList() {
      getPayListReq({
        status: 10,
      })
        .then((res) => {
          if (res.code === 1) {
            this.payList = res.data;
          }
        });
    },
     // 经办人列表
    getEmployee() {
      getEmployeeReq()
        .then((res) => {
          if (res.code === 1) {
            this.employeeList = res.data;
          }
        });
    },
    handleDele(index) {
      this.list.splice(index, 1);
      if (!this.list.length) {
        this.form = {
          ...this.form,
          member_name: '',
          order_number: '',
          order_id: '',
          main_store_name: '',
        }
      }
    },
    handleIndex({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 添加
    handleAdd(item, index) {
      item.goods[index] = {
        ...item.goods[index],
        pay_ammout: item.goods[index].oneprice,
        num: item.goods[index].goods_pcs,
        return_num: item.goods[index].canDoPcs,
      }
      // 如果是第一次添加
      if (!this.form.order_id) {
        this.list.push(item.goods[index]);
        this.dialogVisible = false;
        this.form = {
          ...this.form,
          order_number: item.order_number,
          member_name: item.member_name,
          order_id: item.order_id,
          member_id: item.member_id,
          main_store_name: item.main_store_name,
        };
      } else {
        // 如果已经添加过了
        // 如果是同一个订单，判断是否是同一个商品
        if (item.order_id === this.form.order_id) {
          const arr = this.list.filter((good) => {
            return good.goods_number === item.goods[index].goods_number;
          });
          // 货号不等，可以添加
          if (!arr.length) {
            this.list.push(item.goods[index]);
            this.dialogVisible = false;
          }
          // 货号相等，但是仓库和是否赠品其中一个不等，可以添加
          if (arr.length) {
            let isGiftNum = 0;
            let warehouse_id_num = 0;
            arr.forEach((i) => {
              if (i.isGift !== item.goods[index].isGift) {
                isGiftNumi += 1;
              }
              if (i.warehouse_id !== item.goods[index].warehouse_id) {
                warehouse_id_num += 1;
              }
              // 全部都不同 是否赠品 或 仓库
              if ((isGiftNum === arr.length) || (warehouse_id_num === arr.length)) {
                this.list.push(item.goods[index]);
                this.dialogVisible = false;
              } else {
                this.$message.warning('该货品已添加');
              }
            })
          }
        } else {
          // 如果不是同一个订单
          this.$message.warning("只可以添加同一订单的货品");
        }
      }
      // this.list.push(item.goods[index]);
    },
    // 获取订单货品表头
    getOrderGoodsTitle() {
      getOrderGoodsTitleReq().then((res) => {
        if (res.code === 1) {
          res.data.forEach((item) => {
            if (item.field_alias === "goods_number") {
              item.t_width = 150;
            } else if (item.field_alias === "goods_name") {
              item.t_width = 300;
            }
          });
          this.columnList2 = res.data;
        }
      });
    },
    handleSearch() {
      getOrderListByGoodsNumberReq({
        goods_number: this.goods_number,
      }).then((res) => {
        if (res.code === 1) {
          if (!res.data.total) {
            this.$message.warning("没有货品可以添加");
            return;
          }
          this.goods_number = "";
          // 只有一个订单 并且 订单里只有一个商品的时候，直接选
          if (res.data.total === 1) {
            const item = res.data.list[0];
            if (item.goods.length === 1) {
              // 如果是第一次添加
              item.goods[0] = {
                  ...item.goods[0],
                  pay_ammout: item.goods[0].oneprice,
                  num: item.goods[0].goods_pcs,
                  return_num: item.goods[0].canDoPcs,
                }
              if (!this.form.order_id) {
                this.list.push(item.goods[0]);
                this.form = {
                  ...this.form,
                  order_number: item.order_number,
                  member_name: item.member_name,
                  order_id: item.order_id,
                  member_id: item.member_id,
                  main_store_name: item.main_store_name,
                };
              } else {
                // 如果已经添加过了
                // 如果是同一个订单，判断是否是同一个商品
                if (item.order_id === this.form.order_id) {
                  const arr = this.list.filter((good) => {
                    return good.goods_number === item.goods[0].goods_number;
                  });
                  // 货号不等，可以添加
                  if (!arr.length) {
                    this.list.push(item.goods[0]);
                  }
                  // 货号相等，但是仓库和是否赠品其中一个不等，可以添加
                  if (arr.length) {
                    let isGiftNum = 0;
                    let warehouse_id_num = 0;
                    arr.forEach((i) => {
                      if (i.isGift !== item.goods[0].isGift) {
                        isGiftNumi += 1;
                      }
                      if (i.warehouse_id !== item.goods[0].warehouse_id) {
                        warehouse_id_num += 1;
                      }
                      // 全部都不同 是否赠品 或 仓库
                      if ((isGiftNum === arr.length) || (warehouse_id_num === arr.length)) {
                        this.list.push(item.goods[0]);
                      } else {
                        this.$message.warning('该货品已添加');
                      }
                    })
                  }
                } else {
                  // 如果不是同一个订单
                  this.$message.warning("只可以添加同一订单的货品");
                }
              }
            } else {
              this.dialogVisible = true;
            }
          }
          // 如果有多个订单，弹窗选择
          if (res.data.total > 1) {
            this.dialogVisible = true;
          }
          this.orderInfo = res.data;
        }
      });
    },
    goBack() {
      console.log("点击");
      this.$emit("onBack");
    },
    handleEnter() {
      console.log("enter");
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  width: 54px;
  height: 54px;
  margin-top: 5px;
  object-fit: cover;
}
.btn2 {
  background: white;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ddd;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.form {
  background: white;
  padding: 10px 0;
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
}
</style>

